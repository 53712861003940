import React, { useEffect, useState, useCallback } from "react";
import "./dist/discover.css";
import Footer from "../../components/footer";
import rxValet from "../../images/rxvalet.webp";
import healthAdvocate from "../../images/healthadvocate.png";
import sharePlan from "../../images/share-plan.jpeg";
import spdfspc from "../../images/spdfspc.jpeg";
import eob from "../../images/EOB.png";

const tempFeatures = [
  {
    title: "Benefits",
    image:
      "https://cdn1.iconfinder.com/data/icons/random-1-1/100/health_benefits-512.png",
    link: "https://osai-services.vercel.app/benefits.html",
  },
  {
    title: "Share Plan",
    image: sharePlan,
    link: "https://osai-services.vercel.app/member.html",
  },
  {
    title: "Pharmacy Card",
    image: "https://cdn-icons-png.flaticon.com/512/5841/5841889.png",
    link: "https://osai-services.vercel.app/",
  },
  {
    title: "Documents",
    image: spdfspc,
    link: "https://osai-services.vercel.app/sdpsbc.html",
  },
  {
    title: "Explanation of Benefits",
    image: eob,
    link: "https://osai-services.vercel.app/EOBFolder.html",
  },
  {
    title: "Cigna Provider Search",
    image:
      "https://play-lh.googleusercontent.com/ciEO__U1VJ6RpcMcIW4ABVupR24TYLLy_rYId80f5QBf8bdgyatJAR0cDVRF51YoxFE",
    link: "https://hcpdirectory.cigna.com/web/public/consumer/directory/search",
  },

  {
    title: "Health Advocate",
    image: healthAdvocate,
    link: "https://www.healthadvocate.com/site/",
  },
  {
    title: "RX Valet",
    image: rxValet,
    link: "https://www.myrxvalet.com/",
  },

  {
    title: "Navcare Concierge",
    image:
      "https://media.licdn.com/dms/image/C4E0BAQHeCYVOyyOafQ/company-logo_200_200/0/1585685099524/navcare_logo?e=2147483647&v=beta&t=nQe-BGlTVxB3j9TPxYebvkHeJ2yRhwrNMqZiIuVve2c",
    link: "https://www.valenzhealth.com/members/navcare/#:~:text=NaVcare%20concierge%2Dlevel%20service%3A&text=Introduce%20virtual%20care%20solutions%20when,pocket%20costs%20and%20provider%20billing",
  },
  {
    title: "Maxor Pharmacy",
    image:
      "https://play-lh.googleusercontent.com/wbbwNw8Qd4AalpIM2g32XBEtQNagT0AikXvLbpjiqXzHnF6jiKWmMOZl5jyS6Y2QnA",
    link: "https://www.maxor.com/",
  },
  // NO LINKS BELOW
  {
    title: "Hub Wellness Library",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHEj95BIeJzsjHsBqrkyyUBkBNSQ2NwJcXTg&usqp=CAU",
  },
  {
    title: "Find A Provider",
    image:
      "https://cdn1.iconfinder.com/data/icons/medical-vol-3-5/80/72-512.png",
  },
  {
    title: "AETNA provider research",
    image:
      "https://play-lh.googleusercontent.com/lH2PBKuVXg80Ylc1n203IgggRvnEcfvlEvPaIh-6l5eaSpVF_srFFs4NqcQhyWgiczM=w600-h300-pc0xffffff-pd",
  },
  {
    title: "Doctor On Demand",
    image:
      "https://images.crunchbase.com/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v1498683686/nn7r2eprs49juz6uooho.png",
  },
  {
    title: "Express Scripts",
    image:
      "https://play-lh.googleusercontent.com/chWXM_959bxaPagJ3AHC7RHBmGMmxgg9z8qSf1yuSUnvxIUGkrHOfMHaFPQiDbwN5Vc",
  },
  {
    title: "1800MD",
    image:
      "https://download.cnet.com/a/img/resize/044bef5903677bf5ff0668c30d5eb7357017b7d4/catalog/2020/06/15/51c34f4a-7bb1-4f79-a9b9-98f0c3dbbe54/imgingest-8678855512670709040.png?auto=webp&fit=crop&width=64",
  },
  {
    title: "Telemedicine",
    image: "https://static.thenounproject.com/png/736590-200.png",
  },

  {
    title: "Healthcare",
    image: "https://cdn-icons-png.flaticon.com/512/2966/2966327.png",
  },
  {
    title: "Claims",
    image:
      "https://img.freepik.com/premium-vector/medical-insurance-policy-concept-logo-medicine-card-check-up-clip-board-suite-services-vector-line-icon-save-protect_721440-2288.jpg",
  },

  {
    title: "Wellness Library",
    image: "https://cdn-icons-png.flaticon.com/512/3773/3773911.png",
  },
  {
    title: "Health Risk Assessment",
    image:
      "https://thumbs.dreamstime.com/b/risk-assessment-rubber-stamp-grunge-design-dust-scratches-effects-can-be-easily-removed-clean-crisp-look-color-87647810.jpg",
  },
  {
    title: "Wellness",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZXe3ht0t3WQWapcjQde7I_b3ZEe0xmXD8uVCIcZSWnG3-EeXHBFNemrx6G6nBzCAi41g&usqp=CAU",
  },
  {
    title: "EAP",
    image:
      "https://cdn2.iconfinder.com/data/icons/file-formats-44/780/142_-_EAP-512.png",
  },

  {
    title: "Direct Primary Care",
    image:
      "https://healthc2u.com/wp-content/uploads/2020/05/HC2ULogo_master_transparent-rec-scaled.jpg",
  },

  {
    title: "Southern Scripts",
    image: "https://asset.brandfetch.io/id0Pj26QHs/idX2awRMRY.jpeg",
  },

  {
    title: "Enrollement On Demand",
    image: "https://cdn-icons-png.flaticon.com/512/2247/2247728.png",
  },
  {
    title: "Concierge",
    image: "https://cdn-icons-png.flaticon.com/512/7659/7659682.png",
  },
  {
    title: "Teladoc",
    image:
      "https://play-lh.googleusercontent.com/0klP1YI4As7PFPWxeM35pU0RB3z7PBzznSYR6g3hx2rnVh_JXvas_HJgGOwMv7iSZRk",
  },
  {
    title: "Covid-19",
    image:
      "https://d3n8a8pro7vhmx.cloudfront.net/gratafund/pages/274/attachments/original/1589869845/COVID_LAW_MONITOR_logo_germ.png?1589869845",
  },

  {
    title: "Benefits & Coverage",
    image:
      "https://cdn3.iconfinder.com/data/icons/worker-employment-job-benefits/241/employment-employee-worker-benefit-002-512.png",
  },
  {
    title: "Express scripts - RXBenefits",
    image:
      "https://play-lh.googleusercontent.com/chWXM_959bxaPagJ3AHC7RHBmGMmxgg9z8qSf1yuSUnvxIUGkrHOfMHaFPQiDbwN5Vc",
  },
  {
    title: "Telehealth",
    image:
      "https://png.pngtree.com/png-clipart/20230922/original/pngtree-telemedicine-icon-for-virtual-doctors-on-white-background-vector-png-image_12822759.png",
  },
  {
    title: "More information",
    image:
      "https://www.iconpacks.net/icons/1/free-information-icon-348-thumb.png",
  },
];
export const Discover = () => {
  const [features, setFeatures] = useState(tempFeatures);
  const [search, setSearch] = useState("");

  const fiflterFeatures = useCallback(() => {
    const filteredFeatures = tempFeatures.filter((feature) =>
      feature.title.toLowerCase().includes(search.toLowerCase())
    );
    setFeatures(filteredFeatures);
  }, [search]);

  useEffect(() => {
    fiflterFeatures();
  }, [fiflterFeatures]);

  return (
    <>
      <div className="pt-md-5 pt-3">
        <div className="discover-container m-auto">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div
                  className="text-center f-30 fw-700 py-2"
                  style={{ color: "#ff0000" }}
                >
                  OSAI
                </div>
                <div className="f-25 text-black fw-700 py-3">
                  Discover new health destinations
                </div>
              </div>
              <div className="col-12 pb-2">
                <div className="search-input" style={{ position: "relative" }}>
                  <input
                    className="form-control pr-5 br-50"
                    type="text"
                    placeholder="Search for health tips"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    src="https://cdn3.iconfinder.com/data/icons/feather-5/24/search-512.png"
                    alt="Search Icon"
                    className="search-icon"
                  />
                </div>
              </div>
              {features.map((feature) => (
                <div className="col-6" key={feature.title}>
                  {feature.link ? (
                    <>
                      <a href={feature.link} target="_blank" rel="noreferrer">
                        <div className="pt-3 pointer">
                          <img
                            className="w-100 feature-image"
                            src={feature.image}
                            alt={feature.title}
                          />
                        </div>
                        <div className="pb-3">
                          <div className="f-20 text-black text-center">
                            {feature.title}
                          </div>
                        </div>
                      </a>
                    </>
                  ) : (
                    <>
                      <div className="pt-3 pointer">
                        <img
                          className="w-100 feature-image"
                          src={feature.image}
                          alt={feature.title}
                        />
                      </div>
                      <div className="pb-3">
                        <div className="f-20 text-black text-center">
                          {feature.title}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
