import React from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import login from "../../images/login.png";

const Login = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container center">
        <div className="row">
          <div className="col-12">
            <div className="text-center pb-2">
              <img src={login} alt="Login Page" />
            </div>

            <div className="text-center py-2 fw-700">OSAI</div>
            <div className="text-center pb-5">
              Get personalized health advice. <br /> Login with member ID
            </div>
            <div className="text-center pb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Member ID"
                value={139302}
              />
            </div>
            {/* add birthday input */}
            <div className="text-center pb-2">
              <input
                type="date"
                className="form-control"
                placeholder="Birthday"
                value="1990-01-01"
              />
            </div>
            <div className="text-center">
              <button
                className="btn-red"
                onClick={() => {
                  navigate("/discover");
                }}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
