import React from "react";
import { useNavigate } from "react-router-dom";
import "./welcome.css";
import image from "../../images/Vector.png";

export const Welcome = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container center">
        <div className="row">
          <div className="col-12">
            <div className="text-center pb-5">
              <img src={image} alt="" />
            </div>

            <div className="text-center fw-700 py-2">OSAI</div>
            <div className="text-center pb-5">Stay informed and healthy</div>
            <div className="text-center">
              <button
                className="btn-red"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
