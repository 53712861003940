import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Welcome } from "./pages/welcome";
import Login from "./pages/login";
import { Discover } from "./pages/discover";
import { ChatPage } from "./pages/chat";
import { Profile } from "./pages/profile";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Welcome />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/discover" element={<Discover />}></Route>
        <Route exact path="/chat" element={<ChatPage />}></Route>
        <Route exact path="/profile" element={<Profile />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
