import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./dist/footer.css";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonIcon from "@mui/icons-material/Person";

const Footer = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("discover");

  const handleIconClick = (iconName) => {
    // Perform navigation logic based on iconName
    if (iconName === "discover") {
      navigate("/discover");
    } else if (iconName === "chat") {
      navigate("/chat");
    } else if (iconName === "profile") {
      navigate("/profile");
    }
  };

  // Use useEffect to update the active state after navigation
  useEffect(() => {
    // Extract the current route from the window location
    const currentRoute = window.location.pathname;
    // Determine the active icon based on the current route
    if (currentRoute === "/discover") {
      setActive("discover");
    } else if (currentRoute === "/chat") {
      setActive("chat");
    } else if (currentRoute === "/profile") {
      setActive("profile");
    }
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  return (
    <>
      <div className="footer">
        <div className="container h-100">
          <div className="row align-items-center h-100 text-center">
            <div
              className={
                (active === "discover" ? "" : "opacity-40") + " col pointer"
              }
              onClick={() => handleIconClick("discover")}
            >
              <div className="">
                {active === "discover" ? <HomeIcon /> : <HomeOutlinedIcon />}
              </div>
              <div className="">Home</div>
            </div>

            <div
              className={
                (active === "chat" ? "" : "opacity-40") + " col pointer"
              }
              onClick={() => handleIconClick("chat")}
            >
              <div className="">
                {active === "chat" ? (
                  <ChatIcon />
                ) : (
                  <ChatBubbleOutlineOutlinedIcon />
                )}
              </div>
              <div className="">Chat</div>
            </div>

            <div
              className={
                (active === "profile" ? "" : "opacity-40") + " col pointer"
              }
              onClick={() => handleIconClick("profile")}
            >
              <div className="">
                {active === "profile" ? (
                  <PersonIcon />
                ) : (
                  <PersonOutlineOutlinedIcon />
                )}
              </div>
              <div className="">Profile</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
