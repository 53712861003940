import React from "react";
import "./dist/chat.css";
import Footer from "../../components/footer";

export const ChatPage = () => {
  return (
    <>
      <div className="h-100-vh">
        <div className="h-100 pb-70">
          <iframe
            // src="https://chat.dante-ai.com/embed?kb_id=db446c7d-a60f-4c6b-ba62-e2213dfe7f61&token=b1999f7c-ec20-4b2e-94e1-2329ff2e0ce4&modeltype=gpt-4&logo=dHJ1ZQ==&mode=false"
            src="https://chatgpt-vite.vercel.app/"
            className="frame"
            frameBorder="0"
            title="Benny Health Chat"
          ></iframe>
        </div>

        <Footer />
      </div>
    </>
  );
};
