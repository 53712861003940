import React, { useState } from 'react';
import './dist/profile.css';
import Footer from '../../components/footer';

// Mock function to simulate AI analysis (replace with real API call)
const analyzeHealthData = (data) => {
  // Implement AI analysis logic or API call here
  return 'Health Risk Assessment Pending'; // Placeholder result
};

export const Profile = () => {
  const [profileInfo, setProfileInfo] = useState([
    // Basic Health Information
    { title: 'Blood Type', res: 'A+', editable: false },
    { title: 'Weight (in lbs)', res: '150', editable: true, inputType: 'number' },
    { title: 'Height', res: "5'8''", editable: true, inputType: 'text' },

    // Lifestyle and Habits
    { title: 'How often do you exercise?', res: '', editable: true, inputType: 'text' },
    { title: 'Describe your typical diet', res: '', editable: true, inputType: 'text' },
    { title: 'Do you smoke? If yes, how frequently?', res: '', editable: true, inputType: 'text' },
    { title: 'Alcohol intake frequency', res: '', editable: true, inputType: 'text' },
    { title: 'Sleep patterns (Average hours per night)', res: '', editable: true, inputType: 'text' },

    // Medical History
    { title: 'Known Allergies', res: 'none', editable: true, inputType: 'text' },
    { title: 'Current Medications', res: 'none', editable: true, inputType: 'text' },
    { title: 'Any chronic conditions? (e.g., diabetes, hypertension)', res: '', editable: true, inputType: 'text' },
    { title: 'Family medical history (any hereditary conditions?)', res: '', editable: true, inputType: 'text' },

    // Mental and Emotional Health
    { title: 'How would you rate your stress levels? (Low/Medium/High)', res: '', editable: true, inputType: 'text' },
    { title: 'Do you have regular mental health check-ups?', res: '', editable: true, inputType: 'text' },

    // Preventive Health Practices
    { title: 'Date of last general health check-up', res: '', editable: true, inputType: 'date' },
    { title: 'Frequency of dental check-ups', res: '', editable: true, inputType: 'text' },
    { title: 'Regular health screenings (e.g., blood pressure, cholesterol)', res: '', editable: true, inputType: 'text' },
  ]);

  const [healthRisk, setHealthRisk] = useState('');

  const handleEdit = (index, value) => {
    let newData = [...profileInfo];
    newData[index].res = value;
    setProfileInfo(newData);
  };

  const handleSubmit = () => {
    setHealthRisk(analyzeHealthData(profileInfo));
  };

  return (
    <>
      <div className='pt-md-5 pt-3'>
        <div className='discover-container m-auto'>
          <div className='container'>
            {/* Profile Image and Basic Info */}
            <div className='text-center py-2'>
              <img
                className='profile-img'
                src='https://i.pinimg.com/280x280_RS/a1/81/0a/a1810a48d74ee9b50b938b36f72b0069.jpg'
                alt='Profile'
              />
              <div className='f25 py-2'>Peter Snoufax</div>
              <div className='row'>
                <div className='col-6 text-right'>
                  <div className='b-profile d-inline-block border rounded px-2'>
                    A: 20
                  </div>
                </div>
                <div className='col-6 text-left'>
                  <div className='b-profile d-inline-block border rounded px-2'>
                    G: M
                  </div>
                </div>
              </div>
            </div>

            {/* Health Information Fields */}
            {profileInfo.map((info, index) => (
              <div className='row' key={index}>
                <div className='col-12'>
                  <div className='f-15 fw-400'>{info.title}</div>
                  {info.editable ? (
                    <input
                      type={info.inputType}
                      value={info.res}
                      onChange={(e) => handleEdit(index, e.target.value)}
                      className='form-control'
                    />
                  ) : (
                    <div className='f-15 fw-300'>{info.res}</div>
                  )}
                </div>
                {index !== profileInfo.length - 1 && <hr className='w-100' />}
              </div>
            ))}

            {/* Health Risk Analysis Button */}
            <div className='row mt-3 mb-3'>
              <div className='col-12 text-center'>
                <button className='btn-red' onClick={handleSubmit}>
                  Analyze Health Risk
                </button>
                {healthRisk && <div className='mt-2'>{`Health Risk Assessment: ${healthRisk}`}</div>}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
